<template>
  <div class="bg-gray-darkest">
    <div class="relative" style="padding-bottom: 75%" :aria-label="t('srVideoStream')">
      <video
        muted
        autoplay
        class="absolute w-full h-full object-cover"
        ref="videoPreview"
      ></video>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from '@/services/i18n';
import { defineComponent, onMounted, PropType, ref, watchEffect } from 'vue';

export default defineComponent({
  name: 'VideoPreview',
  props: {
    stream: { type: Object as PropType<MediaStream> }
  },
  setup(props) {
    const videoPreview = ref<HTMLVideoElement | null>(null);

    onMounted(() => {
      watchEffect(() => {
        if (props.stream && videoPreview.value !== null) {
          videoPreview.value.srcObject = props.stream;
        }
      });
    });

    return {
      videoPreview,
      t: useI18n().t
    };
  }
});
</script>

<i18n>
{
  "en": {
    "srVideoStream": "Live video stream"
  },
  "fr": {
    "srVideoStream": "Transmission vidéo en direct"
  }
}
</i18n>
