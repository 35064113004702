<template>
  <div>
    <a-alert variant="danger" icon>
      <p v-if="mediaRecorderNotSupported">
        {{ t('browserNotSupported') }}
      </p>
      <div v-else-if="permissionsDenied">
        <h3 class="text-gray-darker text-lg">
          {{ t('snapshotNeedsAccessTitle') }}
        </h3>
        <i18n-t
          keypath="snapshotNeedsAccessDescription"
          tag="p"
          class="mt-2 prose"
          :i18n="i18n"
        >
          <template #troubleshootingLink>
            <router-link :to="{ name: 'applicant.troubleshooting' }" target="_blank">
              {{ t('troubleshootingTips') }}
              <span class="sr-only">{{ t('srOpenNewTab') }}</span>
            </router-link>
          </template>
        </i18n-t>
      </div>
      <p v-else>
        {{ t('genericError') }}
      </p>
    </a-alert>
    <a-button v-if="permissionsDenied" @click="$emit('retry')" class="block mx-auto mt-8">
      {{ t('retry') }}
    </a-button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from 'vue';
import { useI18n } from '@/services/i18n';
import {
  MediaPermissionsDeniedError,
  MediaRecorderNotSupportedError
} from '../mixins/videoRecorder';

export default defineComponent({
  name: 'VideoRecorderError',
  props: {
    error: { required: true, type: Error }
  },
  emits: ['retry'],
  setup(props) {
    const i18n = useI18n();

    const mediaRecorderNotSupported = computed(
      () => props.error instanceof MediaRecorderNotSupportedError
    );
    const permissionsDenied = computed(
      () => props.error instanceof MediaPermissionsDeniedError
    );

    watchEffect(() => {
      if (
        !(
          props.error instanceof MediaRecorderNotSupportedError ||
          props.error instanceof MediaPermissionsDeniedError
        )
      ) {
        // Throw error of unexpected type so we can debug
        throw props.error;
      }
    });

    return {
      t: i18n.t,
      i18n,
      mediaRecorderNotSupported,
      permissionsDenied
    };
  }
});
</script>

<i18n>
{
  "en": {
    "browserNotSupported": "Your current browser is not supported, please use the latest version of Chrome or Firefox.",
    "genericError": "Unable to get video/audio stream. Make sure your devices are working.",
    "retry": "Retry",
    "snapshotNeedsAccessDescription": "You need to give permission to access your web camera and microphone in order to record your responses. Please allow and click retry. Visit our {troubleshootingLink}.",
    "snapshotNeedsAccessTitle": "Snapshot needs access",
    "srOpenNewTab": "(opens in a new tab)",
    "troubleshootingTips": "Troubleshooting Tips"
  },
  "fr": {
    "browserNotSupported": "Votre navigateur actuel n'est pas compatible, veuillez utiliser la dernière version de Chrome ou Firefox.",
    "genericError": "Impossible d'obtenir un flux vidéo/audio. Assurez-vous que vos appareils fonctionnent.",
    "retry": "Réessayer",
    "snapshotNeedsAccessDescription": "Vous devez autoriser l'utilisation de votre webcam et de votre microphone afin d'enregistrer vos réponses. Visitez notre {troubleshootingLink}.",
    "snapshotNeedsAccessTitle": "Snapshot a besoin d'accès",
    "srOpenNewTab": "(ouvrir dans un nouvel onglet)",
    "troubleshootingTips": "Conseils de dépannage"
  }
}
</i18n>
