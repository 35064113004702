<template>
  <div class="flex flex-col items-center">
    <CheckmarkOutlined class="w-20 text-teal" aria-hidden="true" />
    <div class="text-3xl text-blue-darkest font-bold">
      <slot />
    </div>
    <div class="text-xl text-blue-darkest">
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CheckmarkOutlined from '@/components/common/CheckmarkOutlined.vue';

export default defineComponent({
  name: 'SuccessfullyCompleted',
  components: {
    CheckmarkOutlined
  }
});
</script>
