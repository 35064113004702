export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "browserNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current browser is not supported, please use the latest version of Chrome or Firefox."])},
        "genericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to get video/audio stream. Make sure your devices are working."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
        "snapshotNeedsAccessDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You need to give permission to access your web camera and microphone in order to record your responses. Please allow and click retry. Visit our ", _interpolate(_named("troubleshootingLink")), "."])},
        "snapshotNeedsAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot needs access"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(opens in a new tab)"])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troubleshooting Tips"])}
      },
      "fr": {
        "browserNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre navigateur actuel n'est pas compatible, veuillez utiliser la dernière version de Chrome ou Firefox."])},
        "genericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'obtenir un flux vidéo/audio. Assurez-vous que vos appareils fonctionnent."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
        "snapshotNeedsAccessDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez autoriser l'utilisation de votre webcam et de votre microphone afin d'enregistrer vos réponses. Visitez notre ", _interpolate(_named("troubleshootingLink")), "."])},
        "snapshotNeedsAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot a besoin d'accès"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ouvrir dans un nouvel onglet)"])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils de dépannage"])}
      }
    }
  })
}
