
import { useI18n } from '@/services/i18n';
import { defineComponent, onMounted, PropType, ref, watchEffect } from 'vue';

export default defineComponent({
  name: 'VideoPreview',
  props: {
    stream: { type: Object as PropType<MediaStream> }
  },
  setup(props) {
    const videoPreview = ref<HTMLVideoElement | null>(null);

    onMounted(() => {
      watchEffect(() => {
        if (props.stream && videoPreview.value !== null) {
          videoPreview.value.srcObject = props.stream;
        }
      });
    });

    return {
      videoPreview,
      t: useI18n().t
    };
  }
});
