import { isMediaRecorderSupported, codec } from '@/services/util';
import fixWebmDuration from 'fix-webm-duration';
import { onBeforeUnmount, ref } from 'vue';

export class MediaRecorderNotSupportedError extends Error {}
export class MediaPermissionsDeniedError extends Error {}

export default function videoRecorder() {
  // How many ms per chunk of video recorded:
  const timeSlice = 100;

  const isCapturing = ref(false);
  const isRecording = ref(false);

  let stream: MediaStream | null = null;
  let recorder: MediaRecorder | null = null;
  let recordedBlobs: Blob[] = [];
  let recordingStartedAt = 0;

  function closeStream() {
    if (stream === null) {
      throw new Error('MediaStream not initialized');
    }
    for (const track of stream.getTracks()) {
      track.stop();
    }
    stream = null;
  }

  function init() {
    isCapturing.value = false;
    isRecording.value = false;
    if (stream !== null) {
      closeStream();
    }
    recorder = null;
    recordedBlobs = [];
    recordingStartedAt = 0;
  }

  async function startCapturing() {
    if (!isMediaRecorderSupported()) {
      throw new MediaRecorderNotSupportedError();
    }
    const constraints = {
      audio: true,
      video: { facingMode: 'user' }
    };
    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      isCapturing.value = true;
      return stream;
    } catch (error) {
      const errorName = (error as DOMException).name;
      if (errorName === 'NotAllowedError' || errorName === 'PermissionDeniedError') {
        throw new MediaPermissionsDeniedError();
      }
      throw error;
    }
  }

  function stopCapturing() {
    closeStream();
    isCapturing.value = false;
  }

  async function startRecording() {
    if (stream === null) {
      throw new Error('MediaStream not initialized');
    }
    recordedBlobs = [];
    recorder = new MediaRecorder(stream, { mimeType: codec });
    recorder.ondataavailable = e => {
      recordedBlobs.push(e.data);
    };
    recorder.start(timeSlice);
    recordingStartedAt = Date.now();
    isRecording.value = true;
  }

  async function stopRecording(): Promise<{ video: Blob; duration: number }> {
    if (recorder === null) {
      throw new Error('MediaRecorder not initialized');
    }
    recorder.stop();
    const duration = await new Promise<number>(resolve => {
      if (recorder) {
        recorder.onstop = () => {
          resolve(Date.now() - recordingStartedAt);
        };
      }
    });
    recorder = null;
    recordingStartedAt = 0;
    const video = await new Promise<Blob>(resolve => {
      const video = new Blob(recordedBlobs, { type: codec });
      recordedBlobs = [];
      fixWebmDuration(video, duration, resolve);
    });
    isRecording.value = false;
    return { video, duration };
  }

  function cancelRecording() {
    recorder?.stop();
    recorder = null;
    recordedBlobs = [];
    isRecording.value = false;
  }

  function abort() {
    cancelRecording();
    if (isCapturing.value === true) {
      stopCapturing();
    }
  }

  function reset() {
    abort();
    init();
  }

  onBeforeUnmount(abort);

  return {
    isCapturing,
    isRecording,
    codec,
    startCapturing,
    stopCapturing,
    startRecording,
    stopRecording,
    reset,
    abort
  };
}
