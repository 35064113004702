
import { computed, defineComponent, watchEffect } from 'vue';
import { useI18n } from '@/services/i18n';
import {
  MediaPermissionsDeniedError,
  MediaRecorderNotSupportedError
} from '../mixins/videoRecorder';

export default defineComponent({
  name: 'VideoRecorderError',
  props: {
    error: { required: true, type: Error }
  },
  emits: ['retry'],
  setup(props) {
    const i18n = useI18n();

    const mediaRecorderNotSupported = computed(
      () => props.error instanceof MediaRecorderNotSupportedError
    );
    const permissionsDenied = computed(
      () => props.error instanceof MediaPermissionsDeniedError
    );

    watchEffect(() => {
      if (
        !(
          props.error instanceof MediaRecorderNotSupportedError ||
          props.error instanceof MediaPermissionsDeniedError
        )
      ) {
        // Throw error of unexpected type so we can debug
        throw props.error;
      }
    });

    return {
      t: i18n.t,
      i18n,
      mediaRecorderNotSupported,
      permissionsDenied
    };
  }
});
