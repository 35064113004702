
import { defineComponent } from 'vue';
import CheckmarkOutlined from '@/components/common/CheckmarkOutlined.vue';

export default defineComponent({
  name: 'SuccessfullyCompleted',
  components: {
    CheckmarkOutlined
  }
});
